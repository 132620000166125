import { SEO } from "components/common/SEO";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import buenosaires from "../assets/map/buenosaires.svg";
// import iconEmail from "../assets/icons/email.svg";
// import iconMap from "../assets/icons/maps-and-flags.svg";
import mendoza from "../assets/map/mendoza.svg";
// import iconPhone from "../assets/icons/phone-call.svg";
import ActionRatio from "../components/actionRatio/ActionRatio";
import Layout from "../components/common/layout/layout";
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      site {
        siteMetadata {
          siteUrl
          title
          description
          author
          foundingDate
          socialLinks {
            facebook
            twitter
            linkedin
          }
          address {
            streetAddress
          }
          phone
          phones {
            phone_buenosaires {
              city
              number
            }
            phone_mendoza {
              city
              number
            }
          }
          email
        }
      }
    }
  `);
  const { phones } = data.site.siteMetadata;

  return (
    <Layout>
      <div className={"o-hero-page"}>
        <div className="container flex-center--column">
          <div className="hero-page__title">
            <h1 className={"a-title--hero-page"}>¿Necesita más información?</h1>
          </div>
          <div className="hero-page__desc">
            <p>
            Seleccione un método de contacto y nos pondremos en contacto lo antes posible.
            </p>
          </div>
          <div className="grid-x2 p-top">
            <section className={"a-box-contact"}>
              <img src={buenosaires} width={"54"} alt={"icono"} />
              <p>
                <a
                  href={`tel:${phones.phone_buenosaires.number}`}
                  target={"_blank"}
                  rel={"noopener nofollow  noreferrer"}
                >
                  {phones.phone_buenosaires.city} <br />
                  {phones.phone_buenosaires.number}
                </a>
              </p>
            </section>
            <section className={"a-box-contact"}>
              <img src={mendoza} width={"54"} alt={"icono"} />
              <p>
                <a
                  href={`tel:${phones.phone_mendoza.number}`}
                  target={"_blank"}
                  rel={"noopener nofollow  noreferrer"}
                >
                  {phones.phone_mendoza.city} <br />
                  {phones.phone_mendoza.number}
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
      <ActionRatio />
    </Layout>
  );
};

export const Head = () => (
  <SEO
    title="Póngase en contacto con nosotros | C&R Seguridad"
    description="Estamos a su disposición. Póngase en contacto con cualquiera de nuestras vias disponibles."
    robots="index, follow"
    canonical={"contacto/"}
  />
);

export default ContactPage;
